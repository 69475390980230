import './App.css';
import React, { useRef, useState, useEffect, Suspense } from 'react';
import * as Scroll from 'react-scroll';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import colors from './assets/colors/colors';
import { AppBar, Toolbar, IconButton, Typography, Button, Box, CardMedia, Avatar, ListItemButton, ListItemText, ListItem, List, SwipeableDrawer, Drawer }  from '@mui/material';
import backgroundImg from './assets/images/mountain-assini.jpg';
import headshot from './assets/images/mattd-headshot.png';
import logo from './assets/images/matt-sw-logo-resized.png';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import MenuIcon from '@mui/icons-material/Menu';
import DownloadIcon from '@mui/icons-material/Download';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useWindowDimensions from './WindowDimensions';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { Image, Rotate90DegreesCcw, Rotate90DegreesCcwRounded } from '@material-ui/icons';
import JobList from './JobList';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage, ref } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAUYglIJOvKWD30ryr_aAaeISXSAwrEdKc",
  authDomain: "personal-website-73e3c.firebaseapp.com",
  projectId: "personal-website-73e3c",
  storageBucket: "personal-website-73e3c.appspot.com",
  messagingSenderId: "12283672137",
  appId: "1:12283672137:web:4317f8a6789170caf5f198",
  measurementId: "G-NPB7JXQQYZ"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

// Get a reference to the storage service, which is used to create references in your storage bucket
const storage = getStorage(firebaseApp);

function App() {
  const { height, width } = useWindowDimensions();

  const [topEmail, setTopEmail] = useState(height * 0.5)
  const [drawerOpen, setDrawerOpen] = useState(false)

  const aboutRef = useRef(null)
  const experienceRef = useRef(null)
  const contactRef = useRef(null)

  const executeScrollAbout = () => aboutRef.current.scrollIntoView()
  const executeScrollExperience = () => experienceRef.current.scrollIntoView()
  const executeScrollContact = () => contactRef.current.scrollIntoView()

  useEffect(() => {
    Events.scrollEvent.register('begin', function(to, element) {
      console.log('begin', arguments);
    });

    Events.scrollEvent.register('end', function(to, element) {
      console.log('end', arguments);
    });

    scrollSpy.update();
    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    }
  }, [])

  return (
    <div className="App">
      <BrowserView>
        <AppBar style={{background: colors.turqgrey}} position="sticky">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              {/* <AndroidIcon style={{fontSize: '40'}} /> */}
              <img height={height * 0.06} src={logo} alt="logo" />
            </IconButton>
            <Link activeClass="active" to="about" spy={true} smooth={true} offset={-50} duration={500} style={{marginLeft: 'auto'}}>
              <Button style={{ animation: `fadeIn 1s` }} color="inherit"><Typography variant="h5" style={{ fontFamily: "Nunito-Bold" }}>01. About</Typography></Button>
            </Link>
            <Link activeClass="active" to="work" spy={true} smooth={true} offset={-85} duration={500}>
              <Button style={{ animation: `fadeIn 2s`, marginLeft: '4px' }} color="inherit"><Typography variant="h5" style={{ fontFamily: "Nunito-Bold" }}>02. Experience</Typography></Button>
            </Link>
            {/* <Button style={{ animation: `fadeIn 3s`, marginLeft: '4px' }} color="inherit"><Typography variant="h5" style={{ fontFamily: "Nunito-Bold" }}>03. Work</Typography></Button> */}
            <Link activeClass="active" to="contact" spy={true} smooth={true} offset={-85} duration={500}>
              <Button style={{ animation: `fadeIn 4s`, marginLeft: '4px' }} color="inherit"><Typography variant="h5" style={{ fontFamily: "Nunito-Bold" }}>03. Contact</Typography></Button>
            </Link>
            <Button href="https://drive.google.com/uc?id=1J0LaRu4e6Hdr2htYUVQj2Bp4wKhJ-S_U&export=download" style={{ animation: `fadeIn 5s`, marginLeft: '4px' }} color="inherit" variant="outlined"><Typography variant="h5" style={{ fontFamily: "Nunito-Bold" }}>Resume</Typography></Button>
          </Toolbar>
        </AppBar>
        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
          { height > 490 ?
            <Box style={{ flex: 2, backgroundColor: colors.turqgrey, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
              { height > 720 ?
                <IconButton
                  style={{ animation: `fadeIn 2s`, position: 'sticky', top: height * 0.85, width: '100%', marginLeft: 0}}
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  href="https://www.linkedin.com/in/create-mattd/"
                >
                  <LinkedInIcon  style={{fontSize: '45', color: colors.white}}/>
                </IconButton>
                :
                null
              }
              { height > 720 ?
                <IconButton
                  style={{ animation: `fadeIn 2s`, position: 'sticky', top: height * 0.9, width: '100%', marginLeft: 0}}
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  href="https://github.com/Matt45D"
                >
                  <GitHubIcon style={{fontSize: '45', color: colors.white }}/>
                </IconButton>
                :
                null
              }
            </Box>
            :
            null
          }
          <Box style={{ flex: 28, backgroundColor: colors.turqgrey }}>
            <Box style={{backgroundImage: `url(${backgroundImg})`, padding: '20px', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
              <Typography variant="h3" style={{ animation: `fadeIn 1s`, fontFamily: "Nunito-Bold", color: colors.turqoise }}>Hello, my name is</Typography>
              <Typography variant="h1" style={{ animation: `fadeIn 2s`, fontFamily: "Nunito-Bold", color: colors.white }}>Matthew Davison.</Typography>
              <Typography variant="h1" style={{ animation: `fadeIn 3s`, fontFamily: "Nunito-Bold", color: colors.white }}>I build React Native and React apps for mobile devices and for the web.</Typography>
              <Typography variant="h3" style={{ animation: `fadeIn 4s`, fontFamily: "Nunito-Bold", color: colors.white }}>I'm a software engineer the specializes in building exceptional digital experiences. Currently, I'm focused on building a mobile application centered around the pregnancy experience at Flutter Care.</Typography>
            </Box>
            <Element name='about' style={{ marginBottom: 200 }} >
              <Box style={{backgroundColor: colors.turqgrey, padding: '70px'}}>
                  <Typography variant="h3" style={{ fontFamily: "Nunito-Bold", color: colors.white }}>01. About Me</Typography>
                <Avatar variant="rounded" style={{ resizeMode: "contain", height: height * 0.30, width: width * 0.18, marginLeft: 'auto', marginRight: 'auto', marginTop: 40 }} alt="Matthew Davison" src={headshot} />
                <Typography variant="h5" style={{ fontFamily: "Nunito-Bold", color: colors.white, width: '60%', marginLeft: 'auto', marginRight: 'auto', marginTop: 20 }}>Hello! My name is Matthew and I enjoy creating things that live on the internet. My interest in software development started back in 2016 when I took my first programming course taught in C++. Turns out spending hours staring at a screen trying to debug simple bugs was a lot of fun!</Typography>
                <Typography variant="h5" style={{ fontFamily: "Nunito-Bold", color: colors.white, width: '60%', marginTop: 20, marginLeft: 'auto', marginRight: 'auto'}}>Fast-forward to today, and I’ve had the privilege of working at some great companies working on different software projects that range from Virtual Reality projects in Unity to learning how to deploy Infrastructure as Code (IaC)  to Azure using Terraform.</Typography>
              </Box>
            </Element>
            <Element name='work' style={{ marginBottom: height * 0.6 }} >
              <JobList isMobile={false} />
            </Element>
            {/* <Box style={{backgroundColor: colors.turqgrey, height: height * 0.9, padding: '20px'}}>
              <Typography variant="h3" style={{ fontFamily: "Nunito-Bold", color: colors.white }}>03. Some Things I've Built</Typography>
            </Box> */}
            <Element name='contact' style={{ marginBottom: height * 0.8 }} >
              <Box style={{backgroundColor: colors.turqgrey, padding: '20px'}}>
                <Typography variant="h3" style={{ fontFamily: "Nunito-Bold", color: colors.white }}>03. What's Next?</Typography>
                <Typography variant="h5" style={{ fontFamily: "Nunito-Bold", color: colors.white, marginTop: '30px' }}>Currently, I am working with Flutter Care on contract until December. I am looking for opportunities starting in January, if you are interested feel free to get in touch with me by email at developer@mattdavison.xyz</Typography>
              </Box>
            </Element>
            <Box style={{backgroundColor: colors.turqgrey, height: 50}}>
              <Typography variant="h7" style={{ fontFamily: "Nunito-Bold", color: colors.white }}>{"Designed & Built by Matthew Davison"}</Typography>
            </Box>
          </Box>
          { height > 490 ?
            <Box style={{ flex: 2, backgroundColor: colors.turqgrey }}>
              { height > 490 ?
                <Typography variant="h5" style={{ animation: `fadeIn 2s`, fontFamily: "Nunito-Semi-Bold", color: colors.white, writingMode: 'vertical-rl', position: 'sticky', top: height < 1510 ? height < 1290 ? height < 880 ? '40%' : '50%' : '60%' : '65%', marginLeft: 10 }}>developer@mattdavison.xyz</Typography>
                :
                null
              }
              { height > 680 ?
              <hr style={{animation: `fadeIn 2s`, color: colors.white, backgroundColor: colors.white, height: 250, width: 1, position: 'sticky', top: '86%', marginLeft: 22}} />
              :
              null
            }
            </Box>
            :
            null
          }
        </Box>
      </BrowserView>
      <MobileView key={'right'} style={{background: colors.turqgrey}}>
        <AppBar style={{background: colors.turqgrey}} position="sticky">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              {/* <AndroidIcon style={{fontSize: '40'}} /> */}
              <img height={height * 0.06} src={logo} alt="logo" />
            </IconButton>
            <IconButton
              style={{ animation: `fadeIn 2s`, marginLeft: 'auto'}}
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => setDrawerOpen(true)}
            >
              <MenuIcon  style={{fontSize: '45', color: colors.white}}/>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          anchor={'right'}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          <Box style={{flex: 1, display: 'flex', flexDirection: 'column', backgroundColor: colors.turqgrey}}>
            <Link activeClass="active" to="about" spy={true} smooth={true} offset={-50} duration={500}>
              <Button onClick={() => setDrawerOpen(false)} color="inherit">
                <Typography variant="h5" style={{color: colors.white, fontFamily: "Nunito-Semi-Bold", marginLeft: '20px', marginRight: '20px'}} >01. About</Typography>
              </Button>
            </Link>
            <Link activeClass="active" to="work" spy={true} smooth={true} offset={-85} duration={500}>
              <Button onClick={() => setDrawerOpen(false)} color="inherit">
                <Typography variant="h5" style={{color: colors.white, fontFamily: "Nunito-Semi-Bold", marginLeft: '20px', marginRight: '20px'}} >02. Experience</Typography>
              </Button>
            </Link>
            <Link activeClass="active" to="contact" spy={true} smooth={true} offset={-85} duration={500}>
              <Button onClick={() => setDrawerOpen(false)} color="inherit">
                <Typography variant="h5" style={{color: colors.white, fontFamily: "Nunito-Semi-Bold", marginLeft: '20px', marginRight: '20px'}} >03. Contact</Typography>
              </Button>
            </Link>
            <Button href="https://drive.google.com/uc?id=1J0LaRu4e6Hdr2htYUVQj2Bp4wKhJ-S_U&export=download" color="inherit">
              <DownloadIcon style={{fontSize: '45', color: colors.white}} />
              <Typography variant="h5" style={{color: colors.white, fontFamily: "Nunito-Semi-Bold", marginLeft: '20px', marginRight: '20px'}} >Resume</Typography>
            </Button>
          </Box>
        </Drawer>
        <Box style={{flex: 1, backgroundColor: colors.turqgrey, marginTop: 20}}>
          <Typography variant='h5' style={{animation: `fadeIn 1s`, color: colors.white, fontFamily: "Nunito-Semi-Bold"}}>Hello, my name is</Typography>
          <Typography variant="h4" style={{ animation: `fadeIn 2s`, fontFamily: "Nunito-Bold", color: colors.turqoise }}>Matthew Davison.</Typography>
          <Typography variant="h5" style={{ animation: `fadeIn 3s`, fontFamily: "Nunito-Bold", color: colors.white,  marginTop: '10px' }}>I build React Native and React apps for mobile devices and for the web.</Typography>
          <Typography variant="h5" style={{ animation: `fadeIn 4s`, fontFamily: "Nunito-Bold", color: colors.white,  marginTop: '10px' }}>I'm a software engineer the specializes in building exceptional digital experiences. Currently, I'm focused on building a mobile application centered around the pregnancy experience at Flutter Care.</Typography>
        </Box>
        <Element name='about' >
          <Box style={{backgroundColor: colors.turqgrey, flex: 1, padding: '5%', marginTop: 70}}>
            <Typography variant="h4" style={{ fontFamily: "Nunito-Bold", color: colors.white }}>01. About Me</Typography>
            <Avatar variant="rounded" style={{ resizeMode: "contain", height: height * 0.30, width: width * 0.3, marginLeft: 'auto', marginRight: 'auto', marginTop: 40 }} alt="Matthew Davison" src={headshot} />
            <Typography variant="h6" style={{ fontFamily: "Nunito-Bold", color: colors.white, width: '90%', marginLeft: 'auto', marginRight: 'auto', marginTop: 20 }}>Hello! My name is Matthew and I enjoy creating things that live on the internet. My interest in software development started back in 2016 when I took my first programming course taught in C++. Turns out spending hours staring at a screen trying to debug simple bugs was a lot of fun!</Typography>
            <Typography variant="h6" style={{ fontFamily: "Nunito-Bold", color: colors.white, width: '90%', marginTop: 20, marginLeft: 'auto', marginRight: 'auto' }}>Fast-forward to today, and I’ve had the privilege of working at some great companies working on different software projects that range from Virtual Reality projects in Unity to learning how to deploy Infrastructure as Code (IaC)  to Azure using Terraform.</Typography>
          </Box>
        </Element>
        <Element name='work' >
          <Box style={{ marginTop: 70 }}>
            <JobList isMobile={true} />
          </Box>
        </Element>
        <Element name='contact' >
          <Box style={{backgroundColor: colors.turqgrey, height: height * 0.9, padding: '5%', marginTop: 70}}>
            <Typography variant="h4" style={{ fontFamily: "Nunito-Bold", color: colors.white }}>03. What's Next?</Typography>
            <Typography variant="h6" style={{ fontFamily: "Nunito-Bold", color: colors.white, width: '90%', marginTop: '30px', marginLeft: 'auto', marginRight: 'auto' }}>Currently, I am working with Flutter Care on contract until December. I am looking for opportunities starting in January, if you are interested feel free to get in touch with me by email at developer@mattdavison.xyz</Typography>
          </Box>
        </Element>
        <Box style={{backgroundColor: colors.turqgrey, height: 50}}>
          <Typography variant="h10" style={{ fontFamily: "Nunito-Bold", color: colors.white }}>{"Designed & Built by Matthew Davison"}</Typography>
        </Box>
      </MobileView>
    </div>
  );
}

export default App;
