import React, { useRef, useState, useEffect, Suspense } from 'react';
import colors from './assets/colors/colors';
import { AppBar, Toolbar, IconButton, Typography, Button, Box, CardMedia, Avatar, ListItemButton, ListItemText, ListItem, List }  from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useWindowDimensions from './WindowDimensions';

export default function JobList({isMobile}) {
  const { height, width } = useWindowDimensions();

  const [clickedJob, setClickedJob] = useState('flutter')
  const [flutterColor, setFlutterColor] = useState(colors.gray500)
  const [teradiciColor, setTeradiciColor] = useState(colors.turqgrey)
  const [emergingColor, setEmergingColor] = useState(colors.turqgrey)

  const handleClick = (type) => {
    console.log('My Type', type)
    setClickedJob(type)
    switch(type) {
      case 'flutter': {
        setFlutterColor(colors.gray500)
        setTeradiciColor(colors.turqgrey)
        setEmergingColor(colors.turqgrey)
      } break;
      case 'teradici': {
        setFlutterColor(colors.turqgrey)
        setTeradiciColor(colors.gray500)
        setEmergingColor(colors.turqgrey)
      } break;
      case 'emerging': {
        setFlutterColor(colors.turqgrey)
        setTeradiciColor(colors.turqgrey)
        setEmergingColor(colors.gray500)
      } break;
      default: {
        console.log('Error with handleclick')
      }
    }
  }

  const jobObj = {
    flutter: ['Built and shipped a highly interactive android application onto Google Play for pregnancy tracking using React Native and Firebase services',
  'Developed modern, performant, and reusable code for a diverse array of features including a fetal movement counter, calendar, article library, and data visualisation screen',
  'Communicate and collaborate with multi-disciplinary teams of engineers, designers, clients, and stakeholders on a daily basis',
  'Flutter Care',
  'June 2021 - Present'],
    teradici: ["Built Terraform scripts to automate the deployment of Teradici's multi and single connector cloud solution on Azure and Azure Stack Hub",
  "Designed custom script extensions in Bash and PowerShell to install and configure VMs with Teradici's Cloud Access Software",
  'Collaborated with a team of interns on a project to build a smoke test application in Python3 that tests for proper deployment of Terraform scripts',
  'Teradici',
  'January 2020 - August 2020'],
    emerging: ['Programmed reliable and reusable C# methods to add in player movement through the trigger button on the controller, animations on Unity GameObjects, and main menu functions such as volume control',
  "Developed C# modules using Azure SDK, which converted .wav audio files in real time by querying Azure's Speech to Text API",
  'Designed unit tests using the NUnit framework to test various methods within the project to cover regular and edge cases',
  'UBC Emerging Media Lab',
  'May 2019 - December 2019'],
  };

  const Web = () => {
      return (<Box style={{backgroundColor: colors.turqgrey, padding: '20px'}}>
      <Typography variant="h3" style={{ fontFamily: "Nunito-Bold", color: colors.white }}>02. Where I've Worked</Typography>
      <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 50 }}>
        <List style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '200px'}}>
          <ListItem style={{width: width * 0.2}} disablePadding>
            <ListItemButton style={{backgroundColor: flutterColor, borderRadius: '12px'}} onClick={() => handleClick('flutter')} component="a" href="#simple-list">
              <Typography style={{ fontFamily: "Nunito-Bold", color: colors.white }}>Flutter Care</Typography>
            </ListItemButton>
          </ListItem>
          <ListItem style={{width: width * 0.2}} disablePadding>
            <ListItemButton style={{backgroundColor: teradiciColor, borderRadius: '12px'}} onClick={() => handleClick('teradici')} component="a" href="#simple-list">
              <Typography style={{ fontFamily: "Nunito-Bold", color: colors.white }}>Teradici</Typography>
            </ListItemButton>
          </ListItem>
          <ListItem style={{width: width * 0.2}} disablePadding>
            <ListItemButton style={{backgroundColor: emergingColor, borderRadius: '12px'}} onClick={() => handleClick('emerging')} component="a" href="#simple-list">
              <Typography style={{ fontFamily: "Nunito-Bold", color: colors.white }}>UBC Emerging Media Lab</Typography>
            </ListItemButton>
          </ListItem>
        </List>
        <Box style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: width * 0.5, backgroundColor: colors.turqgrey}}>
          <Typography variant='h4' style={{ fontFamily: "Nunito-Bold", color: colors.white }}>{ clickedJob !== null ? jobObj[clickedJob][3] : 'Loading...' }</Typography>
          <Typography variant='h6' style={{ fontFamily: "Nunito-Bold", color: colors.white }}>{ clickedJob !== null ? jobObj[clickedJob][4] : 'Loading...' }</Typography>
          <Box style={{display: 'flex', flexDirection: 'row'}}>
            <ChevronRightIcon style={{color: colors.white}} />
            <Typography variant='h6' style={{ fontFamily: "Nunito-Bold", color: colors.white, textAlign: 'left' }}>{ clickedJob !== null ? jobObj[clickedJob][0] : 'Loading...' }</Typography>
          </Box>
          <Box style={{display: 'flex', flexDirection: 'row'}}>
            <ChevronRightIcon style={{color: colors.white}} />
            <Typography variant='h6' style={{ fontFamily: "Nunito-Bold", color: colors.white, textAlign: 'left' }}>{ clickedJob !== null ? jobObj[clickedJob][1] : 'Loading...' }</Typography>
          </Box>
          <Box style={{display: 'flex', flexDirection: 'row'}}>
            <ChevronRightIcon style={{color: colors.white}} />
            <Typography variant='h6' style={{ fontFamily: "Nunito-Bold", color: colors.white, textAlign: 'left' }}>{ clickedJob !== null ? jobObj[clickedJob][2] : 'Loading...' }</Typography>
          </Box>
        </Box>
      </Box>
    </Box>)
  }

  const Mobile = () => {
    return(<Box>
      <Typography variant="h4" style={{ fontFamily: "Nunito-Bold", color: colors.white }}>02. Where I've Worked</Typography>
      <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
        <List style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '200px'}}>
          <ListItem style={{width: width * 0.9}} disablePadding>
            <ListItemButton style={{backgroundColor: flutterColor, borderRadius: '12px'}} onClick={() => handleClick('flutter')} component="a" href="#simple-list">
              <Typography style={{ fontFamily: "Nunito-Bold", color: colors.white }}>Flutter Care</Typography>
            </ListItemButton>
          </ListItem>
          <ListItem style={{width: width * 0.9}} disablePadding>
            <ListItemButton style={{backgroundColor: teradiciColor, borderRadius: '12px'}} onClick={() => handleClick('teradici')} component="a" href="#simple-list">
              <Typography style={{ fontFamily: "Nunito-Bold", color: colors.white }}>Teradici</Typography>
            </ListItemButton>
          </ListItem>
          <ListItem style={{width: width * 0.9}} disablePadding>
            <ListItemButton style={{backgroundColor: emergingColor, borderRadius: '12px'}} onClick={() => handleClick('emerging')} component="a" href="#simple-list">
              <Typography style={{ fontFamily: "Nunito-Bold", color: colors.white }}>UBC Emerging Media Lab</Typography>
            </ListItemButton>
          </ListItem>
        </List>
        <Box style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: width * 0.9, backgroundColor: colors.turqgrey}}>
          <Typography variant='h5' style={{ fontFamily: "Nunito-Bold", color: colors.white }}>{ clickedJob !== null ? jobObj[clickedJob][3] : 'Loading...' }</Typography>
          <Typography variant='h6' style={{ fontFamily: "Nunito-Bold", color: colors.turqoise }}>{ clickedJob !== null ? jobObj[clickedJob][4] : 'Loading...' }</Typography>
          <Box style={{display: 'flex', flexDirection: 'row'}}>
            <ChevronRightIcon style={{color: colors.white}} />
            <Typography variant='h6' style={{ fontFamily: "Nunito-Bold", color: colors.white, textAlign: 'left' }}>{ clickedJob !== null ? jobObj[clickedJob][0] : 'Loading...' }</Typography>
          </Box>
          <Box style={{display: 'flex', flexDirection: 'row'}}>
            <ChevronRightIcon style={{color: colors.white}} />
            <Typography variant='h6' style={{ fontFamily: "Nunito-Bold", color: colors.white, textAlign: 'left' }}>{ clickedJob !== null ? jobObj[clickedJob][1] : 'Loading...' }</Typography>
          </Box>
          <Box style={{display: 'flex', flexDirection: 'row'}}>
            <ChevronRightIcon style={{color: colors.white}} />
            <Typography variant='h6' style={{ fontFamily: "Nunito-Bold", color: colors.white, textAlign: 'left' }}>{ clickedJob !== null ? jobObj[clickedJob][2] : 'Loading...' }</Typography>
          </Box>
        </Box>
      </Box>
    </Box>)
  }

  return (
    <Box style={{backgroundColor: colors.turqgrey, flex: 1}}>
    { isMobile === false ? <Web/> : <Mobile/> }
    </Box>
  )
}