const colors = {
    blue100: '#5498ff',
    blue200: '#3682f5',
    blue300: '#0366fc',
    blue400: '#0049b8',
    blue500: '#082c69',
    turqoise: '#40ffdd',
    yellow: '#FFF952',
    gray500: '#333333',
    gray400: '#606060',
    gray300: '#A0A0A0',
    gray200: '#D0CFCF',
    gray100: '#ECECEC',
    purple100: '#CCD6F6',
    purple200: '#8892B0',
    purple300: '#0A192F',
    turqgrey: '#051622',
    white: '#FFFFFF',
    black: '#000000',
};

export default colors;